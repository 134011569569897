@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,700;1,300;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Roboto Slab",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
